import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/App/Layout";

import { getDate, getMonth } from "utils/date-formatter";

const BlogList = (props) => {
  const { data } = props;
  const posts = data.blogs.edges;
  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? "/blog" : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();
  const blogSection = posts.map(({ node: blog }) => (
    <div className="col-md-6 col-lg-4" key={blog.id}>
      <div className="blog-item">
        <a href={`/blog${blog.fields.slug}`} className="blog-img">
          <img
            src={blog.frontmatter.image.replace("/static/", "/")}
            alt="blog-one"
          />
        </a>
        <div className="blog-info">
          <div className="date-box">
            {getDate(blog.frontmatter.date)}{" "}
            <span className="month">{getMonth(blog.frontmatter.date)}</span>
          </div>
          <div className="title-meta">
            <h2>
              <a href={`/blog${blog.fields.slug}`}>{blog.frontmatter.title}</a>
            </h2>
            <div className="post-meta">
              <i className="fa fa-tags"></i>
              {" "}Tags:{" "}
              <ul className="d-inline">
                {
                  blog.frontmatter.tags.map((tag) => (
                    <li>{tag}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="post-content">
          <p>{blog.frontmatter.description}</p>
        </div>
      </div>
    </div>
  ));

  return (
    <Layout location="blog">
      <div className="bread-cumbs-area bread-cumbs-bg">
        <div
          className="diplay-table"
          style={{ backgroundColor: "rgba(0,0,0,0.65)" }}
        >
          <div className="display-table-cell">
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <h1>Articles</h1>
                  <p>
                    What we're learning about and doing with technology,
                    business, and productivity.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="blog" className="our-blog main-blog bg-none">
        <div className="container">
          <div className="row">
            {blogSection}
            <div className="col-lg-12 pagination-area text-center">
              <ul className="pagination">
                {!isFirst && (
                  <li>
                    <Link to="/blog">
                      <i className="fa fa-angle-left"></i>
                    </Link>
                  </li>
                )}
                {!isFirst && (
                  <li>
                    <Link to={prevPage}>{currentPage - 1}</Link>
                  </li>
                )}
                <li className="active">
                  <Link to="#">{currentPage}</Link>
                </li>
                {!isLast && (
                  <li>
                    <Link to={nextPage}>{currentPage + 1}</Link>
                  </li>
                )}
                {!isLast && (
                  <li>
                    <Link to={numPages.toString()}>
                      <i className="fa fa-angle-right"></i>
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default BlogList;

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    blogs: allMarkdownRemark(
      limit: $limit
      skip: $skip
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
            image
            tags
          }
        }
      }
    }
  }
`;
